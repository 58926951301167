// Bundle's Firebase configuration
export default {
  apiKey: "AIzaSyAp0sUzs7tIwXWz-Rp_C_rT4Alh7md9vls",
  authDomain: "bundle-frontend.firebaseapp.com",
  databaseURL: "https://bundle-frontend.firebaseio.com",
  projectId: "bundle-frontend",
  storageBucket: "bundle-frontend.appspot.com",
  messagingSenderId: "586691885900",
  appId: "1:586691885900:web:f005da34079eb661"
};
